import { Paymenthistory } from "../axios";

export const CretePaymenthistory = async (data) => {
  var CretePaymenthistory = await Paymenthistory.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CretePaymenthistory;
};

export const checkByUserPaymenthistory = async (data) => {
  var checkByUserPaymenthistory = await Paymenthistory.post(
    `/checkByUser`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return checkByUserPaymenthistory;
};

export const destroyPaymenthistory = async (data) => {
  var destroyPaymenthistory = await Paymenthistory.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyPaymenthistory;
};
