import { Bbps } from "../axios";


export const FetchBbpsList = async (data) => {
    var bbpsList = await Bbps.post(`/getbbpscategories`,data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    return bbpsList;
  };
export const FetchCatBillersByKey = async (data) => {
    var bbpscatbiller = await Bbps.post(`/cat-billers`,data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    return bbpscatbiller;
  };
export const FetchBill = async (data) => {
    var bbpscatbiller = await Bbps.post(`/fetch-bill`,data)
      .then((res) => {
        console.log(res.data)
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    return bbpscatbiller;
  };
export const PayBill = async (data) => {
    var bbpspaybill = await Bbps.post(`/payBill`,data)
      .then((res) => {
        console.log(res.data)
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    return bbpspaybill;
  };
export const create = async (data) => {
    var bbpscreatebill = await Bbps.post(`/create`,data)
      .then((res) => {
        console.log(res.data)
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    return bbpscreatebill;
  };
export const viewAll = async (data) => {
    var viewall = await Bbps.post(`/viewAll`,data)
      .then((res) => {
        console.log(res.data)
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    return viewall;
  };