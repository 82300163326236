import React, { useEffect, useRef, useState } from "react"
import { viewAll } from "../Api/recharge";
import PacmanLoader from "react-spinners/PacmanLoader";

const RechargeList=()=>{
    const didMountRef = useRef(true)
const [bbpsData , setBbpsData] = useState([])
const [isLoading , setIsLoading] = useState(false)
    useEffect(() => {
        if (didMountRef.current) {
            getcatbillers()
        }
        didMountRef.current = false;
}, []);

const getcatbillers= async()=>{
    setIsLoading(true)
    const datstring={}
    var allmydata = await viewAll(datstring)
    if (allmydata.message == "SUCCESS") {
        setBbpsData(allmydata.data)
        setIsLoading(false)
    }
    else{
        setIsLoading(false)
    }
}
    return(
        <>
          <div className={isLoading == true ? 'parentDisable' : ''} width="100%">
       <div className='overlay-box'>
          <PacmanLoader
            loading={isLoading}
            ariaLabel="Loading"
            color={'#338aec'}
          />
        </div>
        </div>
            <h1 className="text-start font-bold text-xl mt-5">Recharge History</h1>
         <div>
      <div className="mr-5 w-full px-5 py-5 mt-5">
        <table className="w-full border border-black rounded-md bg-white hover:shadow-gray-500/20">
          <tr className="bg-black text-white text-sm font-light w-full">
            <th className="px-6=2 py-2">#</th>
            <th className="px-2 py-2">
              <p>Amount</p>
            </th>
            <th className="px-2 py-2">
              <p>Phone Number</p>
            </th>
            <th className="px-2 py-2">
              <p>Transaction ID</p>
            </th>
            <th className="px-2 py-2">
              <p>Operator  </p>
            </th>
            <th className="px-2 py-2">
              <p>Status</p>
            </th>
          </tr>
          {bbpsData.length !== 0
            ? bbpsData.map((data, index) => (
                <tr className="py-3 border border-black" key={index}>
                  <td className="py-2 px-2 font-semibold border border-black">
                    {index + 1}
                  </td>
                  <td className="py-2 font-semibold border border-black text-sm">
                    {data.amount}
                  </td>
                  <td className="py-2 font-semibold border border-black text-sm">
                    {data.phone}
                  </td>
                  <td className="py-2 font-semibold border border-black text-sm">
                    {data.orderid}
                  </td>
                  <td className="py-2 font-semibold border border-black text-sm">
                    {data.operator_name} ({data.circle})
                  </td>
                 
                  <td className="py-2 font-semibold border border-black text-sm">
                    {" "}
                    {data.status.toLowerCase() === "pending" ? (
                      <button className="bg-yellow-500 rounded p-2 w-24 font-semibold text-white uppercase text-xs">
                        {data.status}
                      </button>
                    ) :data.status.toLowerCase() === "refund" ? (
                      <button className="bg-gray-500 rounded p-2 w-24 font-semibold text-white uppercase text-xs">
                        {data.status}
                      </button>):data.status.toLowerCase() === "failed" ? (
                      <button className="bg-red-500 rounded p-2 w-24 font-semibold text-white uppercase text-xs">
                        {data.status}
                      </button>): (
                      <button
                        className="bg-green-600 rounded p-2 w-24 font-semibold text-white uppercase text-xs"
                        
                      >
                        {data.status}
                      </button>
                    )}
                  </td>
                </tr>
              ))
            : null}
        </table>
      
      </div>
    </div>
        </>
    )
}
export default RechargeList