import { Payout } from "../axios";

export const CretePayout = async (data) => {
  var CretePayout = await Payout.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CretePayout;
};

export const updatePayout = async (data) => {
  var updatePayout = await Payout.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updatePayout;
};

export const viewByUserPayout = async (data) => {
  var viewByUserPayout = await Payout.post(`/viewByUser`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByUserPayout;
};

export const transferPayout = async (data) => {
  var transferPayout = await Payout.post(`/transfer`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return transferPayout;
};

export const statusPayout = async (data) => {
  var statusPayout = await Payout.post(`/status`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return statusPayout;
};

export const verifyPayout = async (data) => {
  var verifyPayout = await Payout.post(`/verify`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return verifyPayout;
};

export const destroyPayout = async (data) => {
  var destroyPayout = await Payout.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyPayout;
};
