import { User } from "../axios";

export const CreteUser = async (data) => {
  var CreteUser = await User.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CreteUser;
};

export const LoginUser = async (data) => {
  var LoginUser = await User.post(`/login`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return LoginUser;
};

export const viewByidUser = async (data) => {
  var viewByidUser = await User.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidUser;
};

export const checkpasswordUser = async (data) => {
  var checkpasswordUser = await User.post(`/checkpassword`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return checkpasswordUser;
};

export const updatepasswordUser = async (data) => {
  var updatepasswordUser = await User.post(`/updatepassword`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updatepasswordUser;
};

export const updateUser = async (data) => {
  var updateUser = await User.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateUser;
};
