import React, { useEffect, useRef, useState } from "react";
import { FetchBbpsList } from "../../Api/bbps";
import { Navigate, useNavigate } from "react-router-dom";
import PacmanLoader from "react-spinners/PacmanLoader";

const Bbps=()=>{
const didMountRef = useRef(true)
const Navigate = useNavigate()
const [bbpsData , setBbpsData] = useState([])
const [isLoading , setIsLoading] = useState(false)
    useEffect(() => {
        if (didMountRef.current) {
            getbbpslist()
    }
    didMountRef.current = false;
    }, []);

    const getbbpslist = async () => {
      console.log("sdfsdfdf")
      setIsLoading(true)
        var allmydata = await FetchBbpsList({})
        if (allmydata.status == "success") {
          console.log(allmydata.data , "dfgdfg")
            setBbpsData(allmydata.data)
            setIsLoading(false)
        }
        else{
          setIsLoading(false)
        }
    };

    const handleClick = (value) => {
      Navigate(`/bbps-detail/${value.key}`, { state: { jsonData: value } });
    };

    return(
        <>
         {isLoading == true?
 <div className={isLoading == true ? 'parentDisable' : ''} width="100%">
       <div className='overlay-box'>
          <PacmanLoader
            loading={isLoading}
            ariaLabel="Loading"
            color ={'#338aec'}
          />
        </div>
      </div>:''}
    <h1 className="text-start font-bold text-xl mt-5">BBPS</h1>
    <div className="flex flex-col justify-center items-center gap-2 md:grid md:grid-cols-3 md:gap-4 mt-12" >
    {bbpsData.map((value,index)=>(
    <div
    style={{ background: index % 2 === 0 ? '#0ea5e9' : '#203A90' }}
      className="cursor-pointer w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5"
      onClick={() => {
        handleClick(value);
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center'}}>
      <img src={value.icon} width="50px" />
        </div>
      <p className="text-center text-white text-xl">{value.name}</p>
    </div>))}
   
  </div>
  </>
    )
}
export default Bbps