import { Recharge } from "../axios";



export const FetchOperatorList = async (data) => {
  var FetchOperatorList = await Recharge.post(`/oplist`,data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return FetchOperatorList;
};

export const FetchPlansList = async (data) => {
  var FetchPlansList = await Recharge.post(`/planslist`,data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return FetchPlansList;
};

export const FetchCircleList = async (data) => {
    var FetchCircleList = await Recharge.post(`/circlelist`,data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    return FetchCircleList;
  };
 
  export const FetchOperator = async (data) => {
    var FetchOperator = await Recharge.post(`/fetchop`,data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    return FetchOperator;
  };
  
  export const doRecharge = async (data) => {
    var FetchOperator = await Recharge.post(`/dorecharge`,data)
      .then((res) => {
        console.log(res,  "dorecharge")
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    return FetchOperator;
  };
  export const createRecharge = async (data) => {
    var FetchOperator = await Recharge.post(`/create`,data)
      .then((res) => {
        console.log(res,"createrecharge")
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    return FetchOperator;
  };
  export const viewAll = async (data) => {
    var viewall = await Recharge.post(`/viewAll`,data)
      .then((res) => {
        console.log(res.data)
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    return viewall;
  };
  

