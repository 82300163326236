/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { viewByorderidTransaction } from "../Api/transaction";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

const View = () => {
  var orderid = sessionStorage.getItem("orderid");
  const [orderdata, setorderdata] = useState([]);
  useEffect(() => {
    getorderdetails();
  }, [orderid]);
  const getorderdetails = async () => {
    var orderdata = await viewByorderidTransaction({ orderid: orderid });
    setorderdata(orderdata);
  };

  return (
    <div>
      <div className="flex mt-5 p-2 gap-4">
        <ArrowLeftIcon
          className="h-8 cursor-pointer"
          onClick={() => {
            window.location.replace("/transaction");
          }}
        />
        <h2 className="text-start text-xl font-bold">Transaction Details</h2>
      </div>
      {orderdata.length !== 0 ? (
        <div className="p-5 bg-gray-100 mt-2 rounded">
          <div className="flex justify-between pb-5 p-5">
            <div>
              <h6 className="font-bold">Transaction ID</h6>
              <p className="mt-2 text-md text-start">{orderdata[0].orderid}</p>
            </div>
            <div>
              <h6 className="text-green-600 font-bold">
                {orderdata[0].status}
              </h6>
              <h6 className="mt-2 text-md text-start">
                ₹ {orderdata[0].amount}
              </h6>
            </div>
          </div>
          <hr className="border border-gray" />
          <div className="p-5">
            <h6 className="font-bold text-start">Beneficiary Details</h6>
            <div className="flex flex-col justify-center items-center gap-4 md:grid md:grid-cols-2 mt-5">
              <div className="flex gap-12">
                <h6>Beneficiary ID</h6>
                <h6 className="font-bold"> </h6>
              </div>
              <div className="flex gap-12">
                <h6>Beneficiary Name</h6>
                <h6 className="font-bold">{orderdata[0].name}</h6>
              </div>
              <div className="flex gap-12">
                <h6>Phone Number</h6>
                <h6 className="font-bold">-</h6>
              </div>
              <div className="flex gap-12">
                <h6>Email ID</h6>
                <h6 className="font-bold"> - </h6>
              </div>
              <div className="flex gap-12">
                <h6>Account Number</h6>
                <h6 className="font-bold">{orderdata[0].account}</h6>
              </div>
              <div className="flex gap-12">
                <h6>IFSC</h6>
                <h6 className="font-bold">{orderdata[0].ifsc}</h6>
              </div>
            </div>
          </div>
          <hr className="border border-gray" />
          <div className="p-5">
            <h6 className="font-bold text-start">Transaction Details</h6>
            <div className="flex flex-col justify-center items-center gap-4 md:grid md:grid-cols-2 mt-5">
              <div className="flex gap-12">
                <h6>UTR NO.</h6>
                <h6 className="font-bold">{orderdata[0].urn_no} </h6>
              </div>
              <div className="flex gap-12">
                <h6>Initiated At</h6>
                <h6 className="font-bold">{orderdata[0].Trans_date}</h6>
              </div>
              <div className="flex gap-12">
                <h6>Phone Number</h6>
                <h6 className="font-bold">-</h6>
              </div>
              <div className="flex gap-12">
                <h6>Email ID</h6>
                <h6 className="font-bold"> - </h6>
              </div>
              <div className="flex gap-12">
                <h6>Account Number</h6>
                <h6 className="font-bold">{orderdata[0].account}</h6>
              </div>
              <div className="flex gap-12">
                <h6>IFSC</h6>
                <h6 className="font-bold">{orderdata[0].ifsc}</h6>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default View;
