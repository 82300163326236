import { Paymentlink } from "../axios";

export const CretePaymentlink = async (data) => {
  var CretePaymentlink = await Paymentlink.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CretePaymentlink;
};

export const updatePaymentlink = async (data) => {
  var updatePaymentlink = await Paymentlink.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updatePaymentlink;
};

export const viewByDatePaymentlink = async (data) => {
  var viewByDatePaymentlink = await Paymentlink.post(`/viewByDate`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByDatePaymentlink;
};

export const viewByDateUserPaymentlink = async (data) => {
  var viewByDateUserPaymentlink = await Paymentlink.post(
    `/viewByDateUser`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByDateUserPaymentlink;
};

export const viewByUserPaymentlink = async (data) => {
  var viewByUserPaymentlink = await Paymentlink.post(`/viewByUser`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByUserPaymentlink;
};

export const viewByUserPendingPaymentlink = async (data) => {
  var viewByUserPendingPaymentlink = await Paymentlink.post(
    `/viewByUserPending`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByUserPendingPaymentlink;
};
