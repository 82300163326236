import React, { useEffect, useRef, useState,CSSProperties  } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FetchCatBillersByKey,FetchBill,create,PayBill } from "../../Api/bbps";
import PacmanLoader from "react-spinners/PacmanLoader";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateUser, viewByidUser, } from "../../Api/User";
import { CreteTransaction } from "../../Api/transaction";
import { createHistory } from "../../Api/History";
import moment from "moment/moment";



const BbpsDetail=()=>{
    const didMountRef = useRef(true)
    const {id} = useParams()
    const [catBiller , setCatBiller] = useState([])
    const [operatordata , setoperatordata] = useState("")
    const [billerName , setBillerName] = useState("")
    const [bbpsData , setBbpsData] = useState("")
    const [dynamicfields , setdynamicfields] = useState([])
    const [formValues, setFormValues] = useState({});
    const [isLoading , setIsLoading] = useState(false)
    const [fetchBill , setFetchBill] = useState({})
    const [billShow , setBillShow] = useState(false)
    const [customerParamsDetails , setCustomerParamsDetails] = useState([])
    const [amount , setAmount] = useState("")
    const [sKey , setSkey] = useState("")
    const [ShowSubmitButton , setShowSubmitButton] = useState(true)
    const location = useLocation();
  
    useEffect(() => {
        if (didMountRef.current) {
            getcatbillers()
            const { jsonData } = location.state || {};
            setBbpsData(jsonData)     
           
        }
        didMountRef.current = false;
}, []);

const getcatbillers= async()=>{
    const datstring={cat_id:id}
    var allmydata = await FetchCatBillersByKey(datstring)
    if (allmydata.status == "success") {
        setCatBiller(allmydata.data)
    }
}
const handleSelectChange = (data) =>{
    // const loopdata = data.split('###');
    const selectedOption = data.target.options[data.target.selectedIndex];
    var params = selectedOption.getAttribute('data-name')
    var params1 = selectedOption.getAttribute('data-biller')
    setBillerName(params1)
    setoperatordata(data.target.value)
    setdynamicfields(JSON.parse(params))
}

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const params = {};
const submitbtn = async() =>{
    if(operatordata == ""){
        toast.error("Operator Required", {
            autoClose: 2000,
            transition: Slide,
          });
          return false
    }


    Object.keys(formValues).forEach((key, index) => {
        params[`param${index + 1}`] = formValues[key];
      });
      if (Object.keys(params).length === 0) {
        toast.error("Please fill all the field first", {
          autoClose: 2000,
          transition: Slide,
        });
        return false;
      }
  
    setIsLoading(true)


const datstring = {
  biller_id: operatordata,
  txnid: "messapay",
  ...params,
};
var allmydata = await FetchBill(datstring);
if(allmydata.status == "success"){
    setIsLoading(false)
    setFetchBill(allmydata.data)
    setCustomerParamsDetails(allmydata.data.CustomerParamsDetails)

    setSkey(allmydata.skey)
    setBillShow(true)
    setAmount(allmydata.data.BillAmount)
}
else{
    setIsLoading(false)
    toast.error(allmydata.message, {
        autoClose: 2000,
        transition: Slide,
      });
}
}

const paybill =async()=>{
    if(amount  == "" && fetchBill.BillAmount == null){
        toast.error("Amount Required", {
            autoClose: 2000,
            transition: Slide,
          });
          return false
    }
   
    if(amount < 10){
        toast.error("Minimum Amount 10 Required", {
            autoClose: 2000,
            transition: Slide,
          });
          return false
    }
   
    setIsLoading(true)
    Object.keys(formValues).forEach((key, index) => {
        params[`param${index + 1}`] = formValues[key];
      });

    var userid = sessionStorage.getItem("user_id");
    var userdata = await viewByidUser({ id: userid });    
    if (Number(userdata[0].balance) >= Number(amount)) {
        var orderId = `mespay${Math.random().toString(36).substring(2, 15)}`;
        var useradata = await viewByidUser({ id: userid });
        var balance = Number(useradata[0].balance) - Number(amount);
        var updatedata = {
          orderid: orderId,
          date: moment().format("YYYY-MM-DD HH:MM:SS"),
          status: "Pending",
          amount: amount,
          mode: null,
          urn_no: null,
          Trans_date: moment().format("YYYY-MM-DD HH:MM:SS"),
          user_id: userid,
          opening_bal: useradata[0].balance,
          closing_bal: balance,
        };
        var transdata = await CreteTransaction(updatedata);
        if(transdata.message == "SUCCESS"){
        if (useradata.length !== 0) {
          var newuserdata = {
            id: userid,
            balance: Number(useradata[0].balance) - Number(amount),
          };
          updateUser(newuserdata);
          var historydata = {
            user_id: userid,
            billercatname : bbpsData.name,
            opening_bal: userdata[0].balance,
            closing_bal: Number(useradata[0].balance) - Number(amount),
            amount: amount,
            type: "bbps",
              status: "Pending",
              trans_id : transdata.data.id,
              trans_order_id : transdata.data.orderid,
              typename : 'BBPS - '+bbpsData.name
          };
          await createHistory(historydata);
        }
        var createvar = {
            billerid : operatordata,
            billercategory : id,
            billercatname : bbpsData.name,
            billername : billerName,
            skey : sKey, 
            billerdetails : fetchBill, 
            orderid : transdata.data.orderid, 
            amount : amount, 
            status : "Pending", 
            transactionid : transdata.data.id, 
            user_id : userid,
            paramdetails : fetchBill.CustomerParamsDetails
        }
        var createbbps = await create(createvar)
        if(createbbps.message == "SUCCESS"){
          var billparams = {
            biller_id : operatordata,
            billername : billerName,
            skey : sKey, 
            amount : amount, 
            status : "Pending", 
            txnid :  transdata.data.orderid, 
              userid : userid,
              ...params
          }
          var paybill = await PayBill(billparams) 
          if(paybill.message == "SUCCESS"){
            toast.success(paybill.data, {
              autoClose: 3000,
              transition: Slide,
            }); 
            setTimeout(() => {
                window.location.reload()
                setShowSubmitButton(true);
              }, 3000);
            setIsLoading(false)
          } else if(paybill.message == "PENDING"){
            toast.warning(paybill.data, {
              autoClose: 3000,
              transition: Slide,
              
            }); 
            setTimeout(() => {
                window.location.reload()
                setShowSubmitButton(true);
              }, 3000);
            setIsLoading(false)
          }else{
            toast.error(paybill.data, {
              autoClose: 3000,
              transition: Slide,
            }); 
            setIsLoading(false)
          }
          }
        else{
          setIsLoading(false)
        }
        }
        else{
          setIsLoading(false)
        }
      }
      else {
  
        toast.error("Your Wallet Balance is low.", {
          autoClose: 2000,
          transition: Slide,
        });
        setIsLoading(false)
      }
  
}
    return(
        <>
       <div className={isLoading == true ? 'parentDisable' : ''} width="100%">
       <div className='overlay-box'>
          <PacmanLoader
            loading={isLoading}
            ariaLabel="Loading"
            color={'#338aec'}
          />
        </div>
        </div>
            <h1 className="text-start font-bold text-xl mt-5">{bbpsData.name}</h1>
            <div className="w-full px-10 py-10">
            <div className="shadow-lg shadow-gray-400/100  w-3/6 bg-white flex flex-col px-5 py-5 rounded-md">

                
                <label className="mt-5 text-left py-1 font-bold">Select Opertaor</label>
                <div>
                <select
                    className="border p-2 border-black rounded outline-none w-full"
                    value={operatordata}
                    alt=""
                    name="opid"
                    onChange={(e) => handleSelectChange(e)}
                    >
                    <option>Select Operator</option>
                    {catBiller &&
                        catBiller.map((opitem, index) => (
                        <option value={`${opitem.billerId}`} key={index} data-name={opitem.parameters} data-biller={opitem.billerName}>
                            {opitem.billerName}
                        </option>
                        ))}
                    </select>
                </div>
                {dynamicfields.map(param => (
                    <>
                        <label className="mt-5 text-left py-1 font-bold" htmlFor={param.name}>{param.desc}:</label>
                        <div key={param.name}>
                        <input
                            className="border p-2 border-black rounded outline-none w-full"
                            type="text"
                            id={param.name}
                            name={param.name}
                            // value={formValues[param.name]}
                            onChange={handleChange}
                            // onBlur={() => validateField(param.name, formValues[param.name])}
                        />
                        {/* {formErrors[param.name] && <span style={{ color: 'red' }}>{formErrors[param.name]}</span>} */}
                        </div>
                    </>
                    ))}
             
{billShow == true ?<>
    {fetchBill.CustomerName && fetchBill.BillNumber && fetchBill.BillPeriod && fetchBill.BillDueDate && fetchBill.BillAmount ?
             <div class="relative overflow-x-auto mt-5">
             <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border border-gray-300">
                 <tbody>
                    {fetchBill.CustomerName ?
                     <tr class="border-b border-gray-200">
                         <th class="w-1/2 border-r border-gray-200">Customer Name:</th>
                         <td class="w-1/2">{fetchBill.CustomerName}</td>
                     </tr>:''}
                     {fetchBill.BillNumber ?
                     <tr class="border-b border-gray-200">
                         <th class="w-1/2 border-r border-gray-200">Bill Number:</th>
                         <td class="w-1/2">{fetchBill.BillNumber}</td>
                     </tr>:''}
                     {fetchBill.BillPeriod ?
                     <tr class="border-b border-gray-200">
                         <th class="w-1/2 border-r border-gray-200">Bill Period:</th>
                         <td class="w-1/2">{fetchBill.BillPeriod}</td>
                     </tr>:''}
                     {fetchBill.BillDueDate  ?
                     <tr class="border-b border-gray-200">
                         <th class="w-1/2 border-r border-gray-200">Bill Due Date:</th>
                         <td class="w-1/2">{fetchBill.BillDueDate}</td>
                     </tr>:''}
                     {fetchBill.BillAmount ?
                     <tr class="border-b border-gray-200">
                         <th class="w-1/2 border-r border-gray-200">Bill Amount:</th>
                         <td class="w-1/2">{fetchBill.BillAmount}</td>
                     </tr>:''}
                     {/* {customerParamsDetails.map((value) => (
                         <tr class="border-b border-gray-200">
                             <th class="w-1/2 border-r border-gray-200">{value.Name}:</th>
                             <td class="w-1/2">{value.Value}</td>
                         </tr>
                     ))} */}
                 </tbody>
             </table>
         </div>:''}
         
         <div className="flex justify-between mt-5 px-1">
         <label className="py-1 font-bold">Amount</label>
     </div>
     <div>
         <input
         type="number"
             className="border p-2 border-black rounded outline-none w-full"
             placeholder="Enter Amount"
             alt=""
             name="amount"
             onChange={(e)=>{setAmount(e.target.value)}}
             value= {fetchBill.BillAmount}
             disabled={!!fetchBill.BillAmount}

         />
       

     </div>    
</>
         :''}
         
                <div className="w-full my-2">
                    {billShow == true ? 
                    <button
                        className="bg-[#007DFA] font-semibold my-4 w-full h-10 rounded text-white"
                    onClick={paybill}
                    >Pay
                    </button>
                    :
                    ShowSubmitButton ? 
                    <button
                    className="bg-[#007DFA] font-semibold my-4 w-full h-10 rounded text-white"
                onClick={submitbtn}
                >Fetch
                </button>:''}
                </div>
              
            </div>
          
        </div>
        <ToastContainer />
        </>
    )
}
export default BbpsDetail