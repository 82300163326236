import React, { useEffect, useState, useRef } from "react";
import {
  FetchOperatorList,
  FetchCircleList,
  FetchOperator,
  createRecharge,
  doRecharge,
  FetchPlansList,
} from "../../Api/recharge";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateUser, viewByidUser } from "../../Api/User";
import { CreteTransaction } from "../../Api/transaction";
import { createHistory } from "../../Api/History";
import PacmanLoader from "react-spinners/PacmanLoader";
import moment from "moment/moment";
import { Tabs, Modal, Button } from "flowbite-react";

const Recharge = () => {
  const [opList, setopList] = useState([]);
  const [circleList, setcircleList] = useState([]);
  const [errorMessage, seterrorMessage] = useState("");
  const [saveAllData, setSaveAllData] = useState({
    mobilenumber: "",
    operator: "",
    circle: "",
    amount: "",
  });
  const [operatorName, setoperatorName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ShowSubmitButton, setShowSubmitButton] = useState(true);
  const [PlansList, setPlansList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [ExplorePlan, ShowExplorePlan] = useState(false);

  // const [failedcount, setfailedcount] = useState(0);
  const didMountRef = useRef(true);

  useEffect(() => {
    if (didMountRef.current) {
      getoplist();
      getcirclelist();
    }
    didMountRef.current = false;
  }, []);

  const getoplist = async () => {
    var allmydata = await FetchOperatorList({});
    if (allmydata.data.length > 0) {
      setopList(allmydata.data);
    }
  };
  const getcirclelist = async () => {
    var allmydata = await FetchCircleList({});
    if (allmydata.data.length > 0) {
      setcircleList(allmydata.data);
    }
  };

  const getOperator = async (data) => {
    var allmydata = await FetchOperator(data);
    if (allmydata.status == "success") {
      if (allmydata.data) {
        setSaveAllData((prevState) => ({
          ...prevState,
          operator: allmydata.data.opid,
          circle: allmydata.data.circle,
        }));
        setoperatorName(allmydata.data.op_name);
        ShowExplorePlan(true);
        console.log(allmydata.data);
      }
    } else if (allmydata.status == "error") {
      seterrorMessage(allmydata.message);
    }
  };

  const getplanslist = async () => {
    const data = {
      opid: saveAllData.operator,
      circle: saveAllData.circle,
    };
    setIsLoading(true);
    var allmydata = await FetchPlansList(data);
    if (allmydata.status == "success") {
      if (allmydata.data) {
        setPlansList(allmydata.data);
        setShowModal(true);
        setIsLoading(false);
      }
    } else if (allmydata.status == "error") {
      seterrorMessage(allmydata.message);
      setIsLoading(false);
    }
  };
  const setPlanAmount = (amt) => {
    console.log("amt", amt);
    setSaveAllData((prevState) => ({
      ...prevState,
      amount: amt,
    }));
    setShowModal(false);
  };

  const onChangeInput = (e) => {
    setSaveAllData((prevState) => ({
      ...prevState,
      mobilenumber: e.target.value,
    }));
    if (e.target.value.length === 10) {
      const mobilenumber = e.target.value;

      const datstring = { mobile: mobilenumber };
      getOperator(datstring);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSaveAllData({ ...saveAllData, [name]: value });
  };

  const handleOperatorchange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setoperatorName(selectedOption.getAttribute("data-name"));
    setSaveAllData((prevState) => ({
      ...prevState,
      operator: e.target.value,
    }));
  };

  const submitbtn = async () => {
    if (saveAllData.mobilenumber == "") {
      toast.error("Mobile Number Required...", {
        autoClose: 3000,
        transition: Slide,
      });
      return false;
    }
    if (saveAllData.operator == "") {
      toast.error("Operator Required...", {
        autoClose: 3000,
        transition: Slide,
      });
      return false;
    }
    if (saveAllData.circle == "") {
      toast.error("Circle Required...", {
        autoClose: 3000,
        transition: Slide,
      });
      return false;
    }
    if (saveAllData.amount == "") {
      toast.error("Amount Required...", {
        autoClose: 3000,
        transition: Slide,
      });
      return false;
    }
    if (saveAllData.amount < 10) {
      toast.error("Minimum Amount Should Be ₹10", {
        autoClose: 3000,
        transition: Slide,
      });
      return false;
    }
    setIsLoading(true);
    setShowSubmitButton(false);
    var userid = sessionStorage.getItem("user_id");
    var userdata = await viewByidUser({ id: userid });
    if (userdata.length !== 0) {
      if (Number(userdata[0].balance) >= Number(saveAllData.amount)) {
        var orderId = `mespay${Math.random().toString(36).substring(2, 15)}`;
        var useradata = await viewByidUser({ id: userid });
        var balance = Number(useradata[0].balance) - Number(saveAllData.amount);
        var updatedata = {
          orderid: orderId,
          date: moment().format("YYYY-MM-DD HH:MM:SS"),
          status: "Pending",
          amount: saveAllData.amount,
          mode: null,
          urn_no: null,
          Trans_date: moment().format("YYYY-MM-DD HH:MM:SS"),
          user_id: userid,
          opening_bal: useradata[0].balance,
          closing_bal: balance,
        };
        var transdata = await CreteTransaction(updatedata);
        if (transdata.message == "SUCCESS") {
          if (useradata.length !== 0) {
            var newuserdata = {
              id: userid,
              balance:
                Number(useradata[0].balance) - Number(saveAllData.amount),
            };
            updateUser(newuserdata);
            var historydata = {
              user_id: userid,
              opening_bal: userdata[0].balance,
              closing_bal:
                Number(useradata[0].balance) - Number(saveAllData.amount),
              amount: saveAllData.amount,
              type: "recharge",
              typename: "Recharge",
              status: "Pending",
              trans_id: transdata.data.id,
              trans_order_id: transdata.data.orderid,
            };
            await createHistory(historydata);
          }
          var rechargevar = {
            phone: saveAllData.mobilenumber,
            user_id: userid,
            amount: saveAllData.amount,
            orderid: orderId,
            operator_id: saveAllData.operator,
            operator_name: operatorName,
            circle: saveAllData.circle,
            transactionid: transdata.data.id,
          };
          var createrecharge = await createRecharge(rechargevar);
          if (createrecharge.message == "SUCCESS") {
            var rechargeparams = {
              mobile: saveAllData.mobilenumber,
              opid: saveAllData.operator,
              amount: saveAllData.amount,
              txnid: transdata.data.orderid,
              userid: userid,
            };
            var dorecharge = await doRecharge(rechargeparams);
            if (dorecharge.message == "SUCCESS") {
              toast.success(dorecharge.data, {
                autoClose: 3000,
                transition: Slide,
              });
              setTimeout(() => {
                window.location.reload();
                setShowSubmitButton(true);
              }, 3000);
              setIsLoading(false);
            } else if (dorecharge.message == "PENDING") {
              toast.warning(dorecharge.data, {
                autoClose: 3000,
                transition: Slide,
              });
              setTimeout(() => {
                window.location.reload();
                setShowSubmitButton(true);
              }, 3000);
              setIsLoading(false);
            } else {
              toast.error(dorecharge.data, {
                autoClose: 3000,
                transition: Slide,
              });
              setShowSubmitButton(true);
              setIsLoading(false);
            }
          } else {
            setShowSubmitButton(true);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          setShowSubmitButton(true);
        }
      } else {
        toast.error("Your Wallet Balance is low.", {
          autoClose: 3000,
          transition: Slide,
        });
        setIsLoading(false);
        setShowSubmitButton(true);
      }
    }
  };
  return (
    <>
      {isLoading == true ? (
        <div className={isLoading == true ? "parentDisable" : ""} width="100%">
          <div className="overlay-box">
            <PacmanLoader
              loading={isLoading}
              ariaLabel="Loading"
              color={"#338aec"}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <h1 className="text-start font-bold text-xl mt-5">Mobile Recharge</h1>
      <div className="w-full px-10 py-10">
        <div className="shadow-lg shadow-gray-400/100  w-3/6 bg-white flex flex-col px-5 py-5 rounded-md">
          <div className="flex justify-between mt-5 px-1">
            <label className="py-1 font-bold">Customer Mobile Number</label>
          </div>
          <div>
            <input
              className="border p-2 border-black rounded outline-none w-full"
              placeholder="Enter Customer Mobile Number"
              alt=""
              name="mobilenumber"
              onChange={(e) => {
                onChangeInput(e);
              }}
            />
            {errorMessage !== "" ? (
              <div className="text-red-500 text-start">{errorMessage}</div>
            ) : null}
          </div>
          <label className="mt-5 text-left py-1 font-bold">
            Select Opertaor
          </label>
          <div>
            <select
              className="border p-2 border-black rounded outline-none w-full"
              value={`${saveAllData.operator}`}
              alt=""
              name="opertaor"
              onChange={(e) => {
                handleOperatorchange(e);
              }}
            >
              <option>Select Operator</option>

              {opList &&
                opList.map((opitem, index) => {
                  return (
                    <>
                      <option
                        value={opitem.opid}
                        key={index}
                        data-name={opitem.name}
                      >
                        {opitem.name}
                      </option>
                    </>
                  );
                })}
            </select>
          </div>
          <label className="mt-5 text-left py-1 font-bold">Select Circle</label>
          <div>
            <select
              className="border p-2 border-black rounded outline-none w-full"
              alt=""
              name="circle"
              value={saveAllData.circle}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <option>Select Circle</option>
              {circleList &&
                circleList.map((circleitem, index) => {
                  return (
                    <>
                      <option value={circleitem} key={index}>
                        {circleitem}
                      </option>
                    </>
                  );
                })}
            </select>
          </div>

          <div className="flex justify-between mt-5 px-1">
            <label className="py-1 font-bold">Amount</label>
          </div>
          <div>
            <input
              className="border p-2 border-black rounded outline-none w-full"
              placeholder="Enter Amount"
              alt=""
              name="amount"
              value={saveAllData.amount}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
          {ExplorePlan ? (
            <a className="font-bold py-2 px-4" onClick={getplanslist}>
              Explore Plans
            </a>
          ) : (
            ""
          )}

          {ShowSubmitButton ? (
            <div className="w-full my-2">
              <button
                className="bg-blue-600 font-semibold my-4 w-full h-10 rounded text-white"
                onClick={submitbtn}
              >
                Submit
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* {paymentlink !== null ? (
          <div className=" shadow-lg shadow-gray-400/100 rounded-md   bg-white  px-5  my-6">
            <p className="text-blue-500 text-left py-1 text-lg ">
              Payment Link
            </p>
            <div className="flex justify-between pt-2 pb-4">
              <input
                type="url"
                placeholder="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEyIWiK8-Yz0mpAJaCzBcwRClyrtbUdM04lA&usqp=CAU"
                className="bg-slate-200 w-full mx-1 px-1 py-2 border-2 border-slate-200 rounded-sm outline-none "
                value={paymentlink}
                disabled
              />
              <div className="flex justify-evenly">
                <div
                  className="bg-green-400 w-10 h-10 flex justify-center items-center rounded-sm mx-1 cursor-pointer"
                  onClick={whstappopen}
                >
                  <AiOutlineWhatsApp className="text-white w-6 h-6" />
                </div>
                <div
                  className="w-10 h-10 flex justify-center items-center border-2 border-black rounded-sm mx-1 cursor-pointer"
                  onClick={copyclipboard}
                >
                  <BsLink45Deg className="text-black w-6 h-6" />
                </div>
              </div>
            </div>
          </div>
        ) : null} */}
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)} size={"md"}>
        <Modal.Header>Available Plans</Modal.Header>
        <Modal.Body>
          <div className="mt-5">
            <Tabs aria-label="Tabs with icons" variant="underline">
              {PlansList &&
                PlansList.map((planitem, index) => {
                  return (
                    <Tabs.Item active title={planitem.group_name}>
                      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 ">
                        {planitem.plans &&
                          planitem.plans.map((plansubitem, index) => (
                            <div class="bg-white p-4 rounded-lg shadow-lg mr-5 mb-5">
                              <div class="flex items-center justify-between">
                                <h2 class="text-lg font-semibold">
                                  {plansubitem.plan_name}
                                </h2>
                                <p class="text-gray-800 font-bold text-lg">
                                  ₹{plansubitem.price}
                                </p>
                              </div>
                              <p class="text-gray-600 text-sm mt-1">
                                {plansubitem.desc}
                              </p>
                              {/* <p class="text-gray-600 text-sm mt-1">Validity: {plansubitem.validity}</p> */}
                              <ul class="text-gray-600 text-sm mt-2 space-y-1">
                                <li>✔ Data: {plansubitem.data}</li>
                                <li>✔ Validity: {plansubitem.validity}</li>
                                {/* <li>✔ 100 SMS per day</li> */}
                              </ul>
                              <button
                                class="mt-4 w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-700"
                                onClick={() => setPlanAmount(plansubitem.price)}
                              >
                                Select
                              </button>
                            </div>
                          ))}
                      </div>
                    </Tabs.Item>
                  );
                })}
            </Tabs>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default Recharge;
