import Header from "../components/Header";
import Payment from "../components/Payment/Payment";
import PaymentTable from "../components/Payment/PaymentTable";
import SidebarNav from "../components/SidebarNav";
import { Routes, Route } from "react-router-dom";
import PaymentLink from "../components/Payment/PaymentLink";
import Transaction from "../components/Payment/Transaction";
import Passwordchange from "../components/Passwordchange";
import Dashboard from "../components/Dashboard";
import View from "../components/View";
import Paymentcheck from "../components/Paymentcheck";
import Historydata from "../components/Historydata";
import Recharge from '../components/Recharge/recharge'
import Bbps from "../components/Bbps";
import BbpsDetail from "../components/Bbps/bbpsdetail";
import RechargeList from "../components/RechargeList";
import BbpsList from "../components/BbpsList";

const Layout = () => {
  var orderid = sessionStorage.getItem("orderidcheck");
  return (
    <>
      {orderid === null ? (
        <div className="">
          <div className="App overflow-y-hidden ">
            <Header />
            <div className="w-full min-h-[90vh] grid grid-cols-12 " >
              <div className="col-span-2 py-4 px-2 border-r" style={{ background: '#0ea5e9',color:'#fff' }}>
                <SidebarNav />
              </div>
              <div className="col-span-10 h-screen px-5 ">
                <Routes>
                  <Route path="/payment" element={<Payment />} />
                  <Route path="/payout" element={<PaymentTable />} />
                  <Route path="/link" element={<PaymentLink />} />
                  <Route path="/transaction" element={<Transaction />} />
                  <Route path="/passwordchange" element={<Passwordchange />} />
                  <Route path="/view" element={<View />} />
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/historydata" element={<Historydata />} />
                  <Route path="/paymentcheck" element={<Paymentcheck />} />
                  <Route path="/recharge" element={<Recharge />} />
                  <Route path="/recharge-history" element={<RechargeList />} />
                  <Route path="/bbps" element={<Bbps />} />
                  <Route path="/bbps-history" element={<BbpsList />} />
                  <Route path="/bbps-detail/:id" element={<BbpsDetail />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Paymentcheck />
      )}
    </>
  );
};

export default Layout;
