import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import Dropdown from "./Dropdown";
import { viewByidUser } from "../Api/User";
import { useSelector } from "react-redux";

const Header = () => {
  const balance = useSelector((store) => store.balance);
  const [profiledata, setprofiledata] = useState([]);
  useEffect(() => {
    getprofile();
  }, []);
  const getprofile = async () => {
    var userid = sessionStorage.getItem("user_id");
    var viewuser = await viewByidUser({ id: userid });
    if (viewuser.length !== 0) {
      setprofiledata(viewuser);
    }
  };
  return (
    <header className="w-full py-8 px-8 h-12 flex items-center justify-between bg-gradient-to-r from-sky-500 to-indigo-500">
      <h1 className="text-2xl text-white font-medium">Dashboard</h1>
      {profiledata.length !== 0
        ? profiledata.map((data, index) => (
            <div className="flex justify-end" key={index}>
              <div className="flex flex-col justify-end" >
                <h3 className="text-white text-lg mx-4">
                  Hello <b>{data.name}</b>
                </h3>
                <strong className="text-white text-2xl mx-4 text-start">
                  Balance: {parseFloat(balance).toFixed(2)}
                </strong>
              </div>
              <Avatar name={`${data.name}`} size={40} round="20px" />
              <Dropdown />
            </div>
          ))
        : null}
    </header>
  );
};

export default Header;
