import { Distributor } from "../axios";

export const viewByidDistributor = async (data) => {
  var viewByidDistributor = await Distributor.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidDistributor;
};

export const updateDistributor = async (data) => {
  var updateDistributor = await Distributor.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateDistributor;
};
