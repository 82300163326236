/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import {
  updatePaymentlink,
  viewByUserPendingPaymentlink,
} from "./Api/Paymentlink";
import { CreteToken, StatusOrder } from "./Api/order";
import {
  checkByUserPaymenthistory,
  destroyPaymenthistory,
} from "./Api/paymenthistory";
import { updateUser, viewByidUser } from "./Api/User";
import { useDispatch } from "react-redux";
import { storeAction } from "./Store/Store";
import { createHistory } from "./Api/History";
import { updateDistributor, viewByidDistributor } from "./Api/distributor";
import moment from "moment";
import { CreteTransaction } from "./Api/transaction";

function App() {
  const dispatch = useDispatch();
  const didMountRef = useRef(true);

  var [userid, setuserid] = useState(null);
  useEffect(() => {
    var userid = sessionStorage.getItem("user_id");
    setuserid(userid);
    getorderdata();
    didMountRef.current = false;
  }, [userid]);
  const getorderdata = async () => {
    var userid = sessionStorage.getItem("user_id");
    if (userid !== null) {
      var viewuser = await viewByidUser({ id: userid });
      if (viewuser.length !== 0) {
        dispatch(
          storeAction.pg_balanceHandler({ pg_balance: viewuser[0].pg_balance })
        );
        dispatch(storeAction.balanceHandler({ balance: viewuser[0].balance }));
      }
      var all_mydata = await viewByUserPendingPaymentlink({ id: userid });
      var allmydata = await all_mydata.filter((data) => {
        return (
          moment(data.createdAt).format("YYYY-MM-DD") ==
          moment().format("YYYY-MM-DD")
        );
      });
      if (allmydata.length !== 0) {
        var data = {
          client_id: process.env.REACT_APP_CLIENT_ID,
          username: process.env.REACT_APP_USER_NAME,
          password: process.env.REACT_APP_PASSWORD,
        };
        var cretetoken = await CreteToken(data);
        if (cretetoken.status === "SUCCESS") {
          await allmydata.filter(async (dataindex, i) => {
            var datanew = {
              orderid: dataindex.orderid,
              client_id: process.env.REACT_APP_CLIENT_ID,
              token: cretetoken.data.token,
            };

            var statusorder = await StatusOrder(datanew);
            if (
              statusorder.ORDERSTATUS !== null &&
              statusorder.ORDERSTATUS !== undefined
            ) {
              if (statusorder.ORDERSTATUS.STATUS === "SUCCESS") {
                var checkuser = await checkByUserPaymenthistory({
                  id: userid,
                  orderid: statusorder.ORDERSTATUS.ORDER_ID,
                });
                if (checkuser.length !== 0) {
                  var newdataupdate = {
                    id: userid,
                    balance:
                      Number(viewuser[0].balance) + Number(checkuser[0].amount),
                  };
                  var historydata = {
                    user_id: userid,
                    opening_bal: viewuser[0].balance,
                    closing_bal:
                      Number(viewuser[0].balance) + Number(checkuser[0].amount),
                    amount: checkuser[0].amount,
                    type: "paymentlink",
                  };
                  if (viewuser[0].dis_id !== null) {
                    var viewdistributor = await viewByidDistributor({
                      id: viewuser[0].dis_id,
                    });
                    var newdistributordata = {
                      id: viewdistributor[0].id,
                      balance:
                        Number(viewdistributor[0].balance) +
                        Number(checkuser[0].distributoramount),
                    };
                    updateDistributor(newdistributordata);
                  }
                  var updatedata = {
                    orderid: statusorder.ORDERSTATUS.ORDER_ID,
                    date: moment().format("YYYY-MM-DD HH:MM:SS"),
                    status: "Success",
                    mode: null,
                    urn_no: null,
                    Trans_date: moment().format("YYYY-MM-DD HH:MM:SS"),
                    user: userid,
                    name: data.name,
                    user_id: userid,
                    opening_bal: viewuser[0].balance,
                    closing_bal:
                      Number(viewuser[0].balance) + Number(checkuser[0].amount),
                    amount: checkuser[0].amount,
                  };
                  await CreteTransaction(updatedata);
                  await createHistory(historydata);
                  await updateUser(newdataupdate);
                  // await destroyPaymenthistory({ id: checkuser[0].id });
                }
                var updatedata = {
                  id: dataindex.id,
                  status: "Success",
                };

                await updatePaymentlink(updatedata);
              } else {
                var checkusernew = await checkByUserPaymenthistory({
                  id: userid,
                  orderid: statusorder.ORDERSTATUS.ORDER_ID,
                });
                var updatedatanew = {
                  id: dataindex.id,
                  status: "Failed",
                };
                // await updatePaymentlink(updatedatanew);
                // await destroyPaymenthistory({ id: checkusernew[0].id });
              }
            }
          });
        }
        setTimeout(() => {
          getorderdata();
        }, 2000);
      }
    }
  };
  return <>{userid === null ? <Login /> : <Layout />}</>;
}

export default App;
