import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { viewByUserPaymentlink } from "../Api/Paymentlink";

const Dashboard = () => {
  const balancevalue = useSelector((store) => store.balance);
  const pg_balancevalue = useSelector((store) => store.pg_balance);

  const [totalcount, settotalcount] = useState(0);
  const [successcount, setsuccesscount] = useState(0);
  const [pendingcount, setpendingcount] = useState(0);
  const [failedcount, setfailedcount] = useState(0);

  useEffect(() => {
    getmypaymentlink();
  }, []);

  const getmypaymentlink = async () => {
    var userid = sessionStorage.getItem("user_id");
    var allmydata = await viewByUserPaymentlink({ id: userid });
    if (allmydata.length !== 0) {
      var successdata = await allmydata.filter((data) => {
        return data.status === "Success";
      });
      var pendingdata = await allmydata.filter((data) => {
        return data.status === "Pending";
      });
      var faileddata = await allmydata.filter((data) => {
        return data.status === "Failed";
      });
      settotalcount(allmydata.length);
      setsuccesscount(successdata.length);
      setpendingcount(pendingdata.length);
      setfailedcount(faileddata.length);
    }
  };
  return (
    <>
      <h1 className="text-start font-bold text-xl mt-5">Dashboard</h1>
      <div className="flex flex-col justify-center items-center gap-2 md:grid md:grid-cols-2 md:gap-4 mt-12" >
        <div className="w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5" style={{ background: '#0ea5e9' }}>
          <p className="text-center text-black text-xl">Balance</p>
          <p className="text-center text-red-500 text-3xl">
            ₹ {parseFloat(balancevalue).toFixed(2)}
          </p>
        </div>
        <div className="w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5" style={{ background: '#203A90' }}>
          <p className="text-center text-black text-xl">PG Balance</p>
          <p className="text-center text-red-500 text-3xl">
            ₹ {parseFloat(pg_balancevalue).toFixed(2)}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-2 md:grid md:grid-cols-4 md:gap-4 mt-12">
        <div
          className="cursor-pointer w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5"
          style={{ background: '#0ea5e9' }}
          onClick={() => {
            window.location.replace("/link");
          }}
        >
          <p className="text-center text-black text-xl">Failed Payment Link</p>
          <p className="text-center text-red-500 text-3xl">{failedcount}</p>
        </div>
        <div
          className="cursor-pointer w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5"
          style={{ background: '#203A90' }}
          onClick={() => {
            window.location.replace("/link");
          }}
        >
          <p className="text-center text-black text-xl">Pending Payment Link</p>
          <p className="text-center text-red-500 text-3xl">{pendingcount}</p>
        </div>
        <div
          className="cursor-pointer w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5"
          style={{ background: '#0ea5e9' }}
          onClick={() => {
            window.location.replace("/link");
          }}
        >
          <p className="text-center text-black text-xl">Success Payment Link</p>
          <p className="text-center text-red-500 text-3xl">{successcount}</p>
        </div>
        <div
          className="cursor-pointer w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5"
          style={{ background: '#203A90' }}
          onClick={() => {
            window.location.replace("/link");
          }}
        >
          <p className="text-center text-black text-xl">Total Payment Link</p>
          <p className="text-center text-red-500 text-3xl">{totalcount}</p>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
