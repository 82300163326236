/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  checkpasswordUser,
  updatepasswordUser,
  viewByidUser,
} from "../Api/User";
import { OtpSend } from "../Api/smtp";

const Passwordchange = () => {
  const [inputs, setInputs] = useState({
    email: "",
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });
  const [userdata, setuserdata] = useState([]);
  const [email, setemail] = useState("");
  const [otp, setotp] = useState(null);
  const [otpchange, setotpchange] = useState(null);
  const [otpstatus, setotpstatus] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    getuserdata();
  }, []);
  const getuserdata = async () => {
    var userid = sessionStorage.getItem("user_id");
    var viewuser = await viewByidUser({ id: userid });
    if (viewuser.length !== 0) {
      setemail(viewuser[0].email);
    }
    setuserdata(viewuser);
  };
  const submitbtn = async () => {
    if (otp == otpchange) {
      if (inputs.newpassword.length === 0) {
        toast.error("New Password Required...", {
          autoClose: 2000,
          transition: Slide,
        });
      } else if (inputs.confirmpassword.length === 0) {
        toast.error("Confirm Password Required...", {
          autoClose: 2000,
          transition: Slide,
        });
      } else {
        if (inputs.newpassword === inputs.confirmpassword) {
          var data = {
            id: userdata[0].id,
            password: inputs.newpassword,
          };
          var updatepass = await updatepasswordUser(data);
          if (updatepass.message === "Updated Successfully") {
            toast.success("New Password Updated Successfully...", {
              autoClose: 2000,
              transition: Slide,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } else {
          toast.error("New Password & Confirm Password Not match...", {
            autoClose: 2000,
            transition: Slide,
          });
        }
      }
    } else {
      toast.error("Otp Not match...", {
        autoClose: 2000,
        transition: Slide,
      });
    }
  };
  const submit_btn = async () => {
    if (inputs.oldpassword.length === 0) {
      toast.error("Old Password Required...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      var checkpassword = await checkpasswordUser({
        email: userdata[0].email,
        password: inputs.oldpassword,
      });
      if (checkpassword === true) {
        var otp = Math.floor(100000 + Math.random() * 900000);
        setotp(otp);
        var data = {
          email: email,
          otp: otp,
          name: userdata[0].name,
        };
        var optsend = await OtpSend(data);
        if (optsend === true) {
          toast.success(
            "Enter the 4-digit verification code sent to your email address..",
            {
              autoClose: 2000,
              transition: Slide,
            }
          );
          setotpstatus(true);
          setInputs({
            oldpassword: "",
            newpassword: "",
            confirmpassword: "",
          });
          setTimeout(() => {
            document.getElementById("newpassword").value = "";
          }, 100);
        }
      } else {
        toast.error("Old Password Not match...", {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  return (
    <>
      <h1 className="text-start font-bold text-xl mt-5">Change Password</h1>
      <div className="py-5 pl-5">
        <div>
          {otpstatus === true ? (
            <>
              <label className="text-left font-bold">OTP</label>
              <input
                class="border border-black p-2 rounded-lg outline-none w-1/3"
                placeholder="Enter your OTP"
                alt=""
                defaultValue={otpchange}
                onChange={(e) => {
                  setotpchange(e.target.value);
                }}
              ></input>
              <label className="text-left font-bold">New Password </label>
              <input
                class="border border-black p-2 rounded-lg outline-none w-1/3"
                placeholder="Enter your New password"
                alt=""
                id="newpassword"
                name="newpassword"
                defaultValue={inputs.newpassword}
                onChange={handleChange}
              ></input>

              <label className="text-left font-bold">
                Confirm New Password
              </label>
              <input
                class="border border-black p-2 rounded-lg outline-none w-1/3"
                placeholder="Enter your Confirm New Password"
                alt=""
                name="confirmpassword"
                defaultValue={inputs.confirmpassword}
                onChange={handleChange}
              ></input>
            </>
          ) : (
            <>
              <label className="text-left font-bold">Email</label>
              <input
                class="border border-black p-2 rounded-lg outline-none w-1/3 bg-gray-200"
                placeholder="Enter Your Email"
                alt=""
                name="oldpassword"
                disabled
                defaultValue={email}
                onChange={handleChange}
              ></input>

              <label className="text-left font-bold">Old Password </label>
              <input
                class="border border-black p-2 rounded-lg outline-none w-1/3"
                placeholder="Enter Your Old Password"
                alt=""
                name="oldpassword"
                defaultValue={inputs.oldpassword}
                onChange={handleChange}
              ></input>
            </>
          )}
        </div>
      </div>
      {otpstatus === true ? (
        <div className="flex justify-start py-2 pl-5">
          <button
            className="bg-blue-500 text-white font-bold rounded rounded text-md px-5 py-3"
            onClick={submitbtn}
          >
            Change Password
          </button>
        </div>
      ) : (
        <div className="flex justify-start py-2 pl-5">
          <button
            className="bg-blue-500 text-white font-bold rounded rounded text-md px-5 py-3"
            onClick={submit_btn}
          >
            Submit
          </button>
        </div>
      )}

      <ToastContainer />
    </>
  );
};

export default Passwordchange;
