import { Transaction } from "../axios";

export const CreteTransaction = async (data) => {
  var CreteTransaction = await Transaction.post(`/create`, data)
    .then((res) => {
      console.log(res.data)
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CreteTransaction;
};

export const viewByUserTransaction = async (data) => {
  var viewByUserTransaction = await Transaction.post(`/viewByUser`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByUserTransaction;
};

export const viewByDateTransaction = async (data) => {
  var viewByDateTransaction = await Transaction.post(`/viewByDate`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByDateTransaction;
};

export const viewByDateUserTransaction = async (data) => {
  var viewByDateUserTransaction = await Transaction.post(
    `/viewByDateUser`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByDateUserTransaction;
};

export const viewByorderidTransaction = async (data) => {
  var viewByorderidTransaction = await Transaction.post(`/viewByorderid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByorderidTransaction;
};

export const updateTransaction = async (data) => {
  var updateTransaction = await Transaction.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateTransaction;
};
