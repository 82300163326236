/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { CreteToken } from "../Api/order";
import { statusPayout } from "../Api/payout";
import {
  updateTransaction,
  viewByorderidTransaction,
} from "../Api/transaction";
import Doneimg from "../assets/done-removebg-preview.png";

const Paymentcheck = () => {
  const [paymentsuccess, setpaymentsuccess] = useState(false);
  var orderid = sessionStorage.getItem("orderidcheck");
  useEffect(() => {
    getstatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  const getstatus = async () => {
    if (orderid !== null) {
      var tokendata = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        username: process.env.REACT_APP_USER_NAME,
        password: process.env.REACT_APP_PASSWORD,
      };
      var cretetoken = await CreteToken(tokendata);
      if (cretetoken.status === "SUCCESS") {
        var statusdata = {
          orderId: orderid,
          token: cretetoken.data.token,
          client_id: process.env.REACT_APP_CLIENT_ID,
        };
        var statuspayout = await statusPayout(statusdata);
        if (statuspayout.STATUS === "SUCCESS") {
          if (statuspayout.ORDERSTATUS.STATUS === "SUCCESS") {
            var checkdata = await viewByorderidTransaction({
              orderid: orderid,
            });
            if (checkdata.length !== 0) {
              var objdata = {
                status: "SUCCESS",
                amount: statuspayout.ORDERSTATUS.TXN_AMOUNT,
                mode: statuspayout.ORDERSTATUS.TXN_MODE,
                urn_no: statuspayout.ORDERSTATUS.UTR_NO,
                Trans_date: statuspayout.ORDERSTATUS.TXN_DATE,
                id: checkdata[0].id,
              };
              var updatedata = await updateTransaction(objdata);
              if (updatedata === "Updated Successfully") {
                setpaymentsuccess(true);
                setTimeout(() => {
                  gettimer();
                }, 100);
              }
            }
          } else {
            getstatus();
          }
        }
      }
    }
  };
  const gettimer = async () => {
    var el = document.getElementById("seconds"),
      total = el.innerHTML,
      timeinterval = setInterval(function () {
        total = --total;
        el.textContent = total;
        if (total <= 0) {
          clearInterval(timeinterval);
          sessionStorage.removeItem("orderidcheck");
          window.location.replace("/transaction");
        }
      }, 1000);
  };
  return (
    <div>
      {/* {paymentsuccess === false ? (
        <div class="ring">
          Please Wait...
          <span className="spantag"></span>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center gap-2 donediv pt-24">
          <img src={Doneimg} alt="" className="doneimg" />
          <h4 className="text-center text-2xl text-green-500">
            Payout is processed and the money has been credited into the
            beneficiary account.
          </h4>

          <p className="mt-5 text-lg">
            You will be redirected in{" "}
            <time>
              <strong id="seconds">5</strong> seconds
            </time>
            .
          </p>
        </div>
      )} */}
      <div className="flex flex-col justify-center items-center gap-2 donediv pt-24">
        <img src={Doneimg} alt="" className="doneimg" />
        <h4 className="text-center text-2xl text-green-500">
          Payout is processed and the money has been credited into the
          beneficiary account.
        </h4>

        <p className="mt-5 text-lg">
          You will be redirected in{" "}
          <time>
            <strong id="seconds">5</strong> seconds
          </time>
          .
        </p>
      </div>
    </div>
  );
};

export default Paymentcheck;
